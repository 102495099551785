import React from 'react';

const HRAForm = ({
  handleFormData,
  values,
  metro,
  handleSubmit,
  validationError,
}) => {
  return (
    <div className='row other-details'>
      <div className='col-md-10 card'>
        <div className='tax-input-block-border'>
          <form>
            <div className='living'>
              <label htmlFor='Living' className='form-label living-label'>
                Living In:
              </label>
              <tbody>
                <tr>
                  <td>
                    <input
                      type='radio'
                      id='1'
                      name='city_input'
                      className='city_input'
                      value='metro'
                      checked={metro === 'metro'}
                      onChange={handleFormData}
                    />
                    <span> Metro City</span>
                    <br />
                    <span className='living-text'>
                      Delhi, Kolkata, Mumbai, Chennai
                    </span>
                  </td>
                  <td>
                    <input
                      type='radio'
                      name='city_input'
                      id='2'
                      className='city_input'
                      value='non_metro'
                      checked={metro === 'non_metro'}
                      onChange={handleFormData}
                    />
                    <span> Other City</span>
                    <br />
                    <span className='living-text'>Other than Metro City</span>
                  </td>
                </tr>
              </tbody>
            </div>
            <div className='mt-1 mb-1'>
              <label
                htmlFor='Basic Salary + DA + Commission Received'
                className='form-label'
              >
                Basic Salary + DA + Commission Received
                <span className='text-danger required'>*</span>
              </label>
              <input
                type='number'
                className='rent-input form-control'
                id='basic_salary'
                name='basic_salary'
                autoComplete='off'
                required
                placeholder='Basic Salary + DA + Commission Received'
                value={values.basic_salary}
                onChange={handleFormData}
              />
              {validationError.basic_salary ? (
                <p className='valid_error text-left text-danger'>
                  {validationError.basic_salary}
                </p>
              ) : (
                ''
              )}
            </div>
            <div className='row'>
              <div className='col-md-6 mb-1'>
                <label htmlFor='HRA Received' className='form-label'>
                  HRA Received
                </label>
                <input
                  type='number'
                  className='rent-input form-control'
                  id='hra_received'
                  name='hra_received'
                  autoComplete='off'
                  placeholder='HRA Received'
                  value={values.hra_received}
                  onChange={handleFormData}
                />
              </div>
              <div className='col-md-6 mb-1'>
                <label htmlFor='Actual Rent Paid' className='form-label'>
                  Actual Rent Paid
                </label>
                <input
                  type='number'
                  className='rent-input form-control'
                  id='rent_paid'
                  name='rent_paid'
                  autoComplete='off'
                  placeholder='Actual Rent Paid'
                  value={values.rent_paid}
                  onChange={handleFormData}
                />
              </div>
            </div>
            <div className=' mb-1'>
              <label htmlFor='Email' className='form-label'>
                Email<span className='text-danger required'>*</span>
              </label>
              <input
                type='email'
                className='rent-input form-control'
                id='email'
                name='email'
                required
                autoComplete='off'
                value={values.email}
                onChange={handleFormData}
                placeholder='Enter Email'
              />
              {validationError.email ? (
                <p className='valid_error text-left text-danger'>
                  {validationError.email}
                </p>
              ) : (
                ''
              )}
            </div>
            <div className='col-md-4 hra-btn float-end'>
              <button
                type='submit'
                className='btn btn-md form-next-btn btn-success m-2'
                onClick={handleSubmit}
              >
                Submit <i className='bi bi-arrow-right'></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HRAForm;
