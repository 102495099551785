import React, { useState } from 'react';
import FAQAccord from '../GFlush';
import ImgContent from '../GSTTemp/ImgContent';
import HRAExemption from './HRAExemption';
import HRAForm from './HRAForm';


export default function HrA() {
    /* Img Content Component Data */
    const rentIcData = {
      id: 'hra',
      mt_div: 'mt-5',
      mt_img: '',
      header: 'What is an HRA?',
      image: '/imgs/assited/accounting-reci-acc.png',
      alt_tag: 'House Rent Allowance',
      points: [
        {
          head: '',
          content: `HRA, or House Rent Allowance, is a payment that
                 salaried people receive from their employer to cover their housing costs.`,
          icon: true,
        },
        {
          head: '',
          content: `For the employee who lives in a rented home, the money
                received is partially free from tax.`,
          icon: true,
        },
        {
          head: '',
          content: `The entire sum would be taxable if the employee does
                not pay rent and lives in his or her own home.`,
          icon: true,
        },
        {
          head: 'Get a tax benefit from my HRA.',
          content: `By giving your parents rent, you can obtain a tax
                break on your HRA. The use of a rent agreement is advised.`,
          icon: true,
        },
        {
          head: '',
          content: `Rent receipts are required in order to claim
                HRA exemption. Additionally, your parents must disclose your
                rental income on their income tax return.`,
          icon: true,
        },
      ],
    };

    /* FaQ data */
    var RentGenFAQ = [
      {
        header: 'What documentation must be provided to request HRA?',
        body: [
          {
            content: `Rent receipts are not necessary if your monthly rent
                    is less than Rs. 3,000.`,
            icon: true,
          },
          {
            content: `You must produce rent receipts if your monthly rent
                     exceeds Rs. 3,000. To create rent receipts, go here.`,
            icon: true,
          },
          {
            content: `If your monthly rent exceeds Rs. 8,333, your
                    landlord's PAN must also be provided.`,
            icon: true,
          },
          {
            content: `If the landlord does not have a PAN, the landlord
                     must make a declaration to that effect.`,
            icon: true,
          },
        ],
      },
      // 2
      {
        header:
          "If I live in my spouse's home, am I still eligible for the HRA tax benefit?",
        body: [
          {
            content: `The HRA tax benefit cannot be claimed if you pay your spouse's rent.
                     So, HRA exemptions are not accessible to you if you live in a home owned by
                     your husband.However, if you still want to ask for an exemption, be prepared
                     to go to court since the income tax officer might agree, according to a ruling
                     from the Ahmadabad Tribunal in one of the cases.`,
            icon: false,
          },
        ],
      },
      {
        header: `Can I get the HRA tax benefit if I own my home?`,
        body: [
          {
            content: `No, if you own your home, you cannot benefit from HRA's tax advantages.
                    One cannot pay one's own rent. Therefore, there is no HRA exemption available,
                    and the entire HRA earned is subject to "Income from Salary" taxation.`,
            icon: false,
          },
        ],
      },
      // 3
      {
        header: `Does the HRA exemption require the landlord's PAN? What should you do if your
             landlord lacks a PAN?`,
        body: [
          {
            content: `It is necessary to report the landlord's PAN in order to qualify for
                    HRA exemption if the annual rent paid exceeds Rs. 1,00,000/-.`,
            icon: true,
          },
          {
            content: ` If the landlord does not have a PAN, a landlord declaration is necessary.
                    Click here to obtain the declaration of landlord format.`,
            icon: true,
          },
        ],
      },
      //4
      {
        header: `Although I own a home, I currently reside in a rental. Do I qualify for HRA exemption?`,
        body: [
          {
            content: `There is no requirement that you not own a home in order to qualify for HRA.
                    Even if you own a home but live in a rented space, you are still eligible for an
                    exemption (in the same city or in a different city).`,
            icon: false,
          },
        ],
      },
      {
        header: `Can I receive HRA and house loan tax benefits at the same time?`,
        body: [
          {
            content: `Certainly, yes. Both may be claimed. You may be able to claim an
                     HRA tax exemption if you are living in a leased home. Additionally, if you
                      have a mortgage, you are also eligible to get benefits related to your mortgage.`,
            icon: false,
          },
        ],
      },
      {
        header: `Can each working spouse obtain a separate HRA tax benefit?`,
        body: [
          {
            content: `Yes, why not, so long as they both have rent to pay. It will
                     also be beneficial if the landlord gives two separate rent receipts or
                     indicates on the rent receipt how much of the rent is paid by each tenant.`,
            icon: false,
          },
        ],
      },
      {
        header: `How can I claim the HRA if it is not stated in the FORM 16?`,
        body: [
          {
            content: `It has frequently been noticed that businesses fail to provide an HRA
                    exception in Form 16 when full information is not available. You can immediately
                    claim the exemption in your IT return if your HRA claim was not taken into account
                    in form 16.`,
            icon: true,
          },
          {
            content: `Our professionals are here to assist you in efficiently filing your IT
                    return and claiming all of the relevant tax exemptions and deductions.`,
            icon: true,
          },
        ],
      },
      {
        header: `How may a self-employed person use the rent payment as a tax deduction?`,
        body: [
          {
            content: `Since a self-employed person does not get a wage, there is
                    no HRA and no need to discuss an exemption from the HRA.`,
            icon: true,
          },
          {
            content: `However, there is a different provision in the Income Tax Act
                    that can be used to address this circumstance. Section 80GG allows a person
                    who is not receiving HRA but is paying rent for his home to claim a deduction.`,
            icon: true,
          },
        ],
      },
    ];
    const [validationError, setValidationError] = useState({
      email: '',
      basic_salary: '',
    });
    const [currentStep, setCurrentStep] = useState(1);
    const [metro, setMetro] = useState('metro');
    console.log(typeof metro);
    const [exemption, setExemption] = useState(0);
    //state for form data
    const [formData, setFormData] = useState({
      // city_input: '',
      basic_salary: '',
      hra_received: '',
      rent_paid: '',
      email: '',
    });

    // const [receiptData, setReceiptData] = useState(false);

    // function for going to next step by increasing step state by 1
    const nextStep = () => {
      setCurrentStep(currentStep + 1);
    };
    // function for going to previous step by decreasing step state by 1
    const prevStep = () => {
      setCurrentStep(currentStep - 1);
    };

    // handling form input data by taking onchange value and updating our previous form data state
    const handleInputData = (e) => {
      // input value from the form
      const { name, value } = e.target;

      //updating for data state taking previous state and then adding new value to create new object
      if (e.target.checked) {
        setMetro(e.target.value);
      }
      setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      if (!formData.email) {
        setValidationError({
          ...validationError,
          email: 'Please Enter Email',
        });
        return;
      }
      if (!formData.basic_salary) {
        setValidationError({
          ...validationError,
          basic_salary: 'Please Enter Basic Salary',
        });
        return;
      }
      let metroValue;
      if (metro === 'metro') {
        alert('asdsadd');
        metroValue = formData.basic_salary;
      } else {
        metroValue = formData.basic_salary * 0.4;
      }
      // const x = metro;
      const y = formData.rent_paid - formData.basic_salary * 0.1;
      const calculatedExemption =
        Math.min(formData.hra_received, metroValue, y) < 0
          ? 0
          : Math.min(formData.hra_received, metroValue, y);
      setExemption(calculatedExemption);
      nextStep();
    };
    const renderSwitch = (param) => {
      switch (param) {
        // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
        case 1:
          return (
            <HRAForm
              nextStep={nextStep}
              metro={metro}
              handleFormData={handleInputData}
              values={formData}
              handleSubmit={handleSubmit}
              validationError={validationError}
            />
          );
        // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
        case 2:
          return (
            <HRAExemption
              // nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={handleInputData}
              exemption={exemption}
            />
          );
        // default case to show nothing
        default:
          return <div></div>;
      }
    };
    return (
      <>
        <section className='house-rent otherdetail'>
          <div className='container  mt-5'>
            <div className='row house-rent-receipt mt-5'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2'>
                <div className=' px-0 pt-4 pb-0 mt-5 mb-3'>
                  <h2 className='section-header mt-5'>
                    Free HRA Calculator
                    <div className='bar'></div>
                  </h2>
                  <p style={{ marginTop: '-30px' }}>
                    <strong>
                      Even if you do not receive HRA, you may now claim a
                      deduction of up to Rs. 60,000 under Section 80GG.
                    </strong>
                  </p>
                  <p>
                    Calculate your potential exemption as well, should you be
                    eligible for the HRA.
                  </p>

                  <div className='hra-form'>
                    <form id='msform'>
                      <div className='row hra-form'>
                        <div className='col-md-1'></div>
                        <div className='col-md-4 hra-form-div1 card'>
                          <h4 className='section-header'>HRA Calculator</h4>
                          <p className='desc'>
                            Salary would be calculated as Basic Pay + D.A. for
                            the calculations above (if considered for retirement
                            benefits)
                          </p>

                          <div className='row'>
                            <div className='col-md-1'>
                              <i className='bi bi-check-circle-fill'></i>
                            </div>
                            <div className='col-md-11'>Actual HRA was paid</div>
                          </div>
                          <div className='row'>
                            <div className='col-md-1'>
                              <i className='bi bi-check-circle-fill'></i>
                            </div>
                            <div className='col-md-11'>
                              Rent less 10% of salary is paid.
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-1'>
                              <i className='bi bi-check-circle-fill'></i>
                            </div>
                            <div className='col-md-11 desc'>
                              40% of Salary (in Mumbai, Delhi, Chennai, and
                              Kolkata, 50% of Salary)
                            </div>
                          </div>
                        </div>

                        <div className='col-md-6 mt-5'>
                          {renderSwitch(currentStep)}
                        </div>
                        <div className='col-md-1'></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ImgContent item={rentIcData} />

        <section className='rent-faq'>
          <FAQAccord items={RentGenFAQ} />
        </section>
      </>
    );
};
