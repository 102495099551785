import React from 'react'
import HrA from '../../components/HRACalculator/HRA'
import Layout from '../../components/Layout'
import { Seo } from '../../components/seo'

export default function HRACalculator() {
    return (
      <>
        <Seo
          title='HRA Calculator'
          keywords='Keywords'
          description='Description'
          name='TODAYFILINGS'
          type='Website'
          author='TODAYFILINGS'
        />
        <Layout>
          <HrA />
        </Layout>
      </>
    );
}
