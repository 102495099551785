import React from 'react'

const HRAExemption = ({ prevStep, exemption }) => {
  return (
    <div className='row exemption'>
      <div className='col-md-10 card'>
        <div className='col-md-3'>
          <button
            className='btn btn-sm  form-prev-btn btn-primary'
            onClick={prevStep}
          >
            <i className='bi bi-arrow-left'></i>Back
          </button>
        </div>
        <div className='tax-input-block-border'>
          <form>
            <div className='hra-result'>
              <span className='hra-result-text'>EXEMPTION:</span>
              <br />
              <strong id='hra_result'>₹ {exemption}</strong>
            </div>
          </form>

          <button
            style={{ marginTop: '27px' }}
            className='btn btn-md form-next-btn btn-success'
          >
            File ITR Now <i className='bi bi-arrow-right'></i>
          </button>
          {/* <Link
            to='/mortgages/itrfiling'
            style={{ marginTop: '27px' }}
            className='btn btn-md form-next-btn btn-success'
          >
            File ITR Now <i className='bi bi-arrow-right'></i>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default HRAExemption;
